import React, { useState, useEffect } from "react";
// import BgSuccess from "../../images/bg-success.svg";
import { Button, notification, Descriptions } from "antd";
import _ from "lodash";
import $axios from "../../services/axios";
import { useLocation, useHistory } from "react-router-dom";

const PaymentResult = ({ setLoading, loading }) => {
  const location = useLocation();
  const history = useHistory();
  const [statusPayment, setStatusPayment] = useState(false);
  const [bookingTaxi, setBookingTaxi] = useState(null);

  useEffect(() => {
    handleBooking();
  }, []);

  const handleBooking = async () => {
    setLoading(true);
    const queryParams = new URLSearchParams(location.search);
    const amount = queryParams.get("amount");
    const payment_type = queryParams.get("payment_type");
    const reference_number = queryParams.get("reference_number");
    const status = queryParams.get("status");
    const trans_ref_no = queryParams.get("trans_ref_no");
    const website_id = queryParams.get("website_id");
    const signature = queryParams.get("signature");
    const messageVtc = queryParams.get("message");
    setStatusPayment(status === "1");
    try {
      const body = {
        OrderId: reference_number,
        Amount: amount,
        Message: messageVtc,
        Status: Number(status),
        PaymentType: payment_type,
        ReferenceNumber: reference_number,
        TransRefNo: trans_ref_no,
        WebsiteId: website_id,
        Signature: signature,
      };
      await $axios.$post("/payment/paymentHandle", body);
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      if (status === "1") {
        const res = await $axios.$get(
          `/payment/GetPaymentInfoTemp?orderId=${reference_number}`
        );
        setBookingTaxi(res.data.data.data);
      }
      setLoading(false);
      setTimeout(() => {
        const { pathname } = location;
        history.replace(pathname);
      }, 2000);
    }
  };

  const items = [
    {
      key: "fullName",
      label: "Họ tên",
      children: bookingTaxi?.customerName || "",
    },
    {
      key: "phone",
      label: "Số điện thoại",
      children: bookingTaxi?.customerPhone,
    },
    {
      key: "route",
      label: "Chuyến đi",
      children: (
        <>
          {bookingTaxi?.pickupAddress}
          <br />
          {bookingTaxi?.destinationAddress}
        </>
      ),
    },
    {
      key: "VAT",
      label: "VAT",
      children: (
        <>
          {bookingTaxi?.vatMst ? (
            <>
              Mã số thuế: {bookingTaxi?.vatMst}
              <br />
              Họ tên: {bookingTaxi?.vatFullName}
              <br />
              Email: {bookingTaxi?.vatEmail}
            </>
          ) : (
            "Không"
          )}
        </>
      ),
    },
    {
      key: "twoWay",
      label: "Khứ hồi",
      children: <>{bookingTaxi?.roundTrip === 1 ? "Có" : "Không"}</>,
    },
    {
      key: "typeCar",
      label: "Loại xe",
      children: <>{bookingTaxi?.typeOfCarName}</>,
    },
    {
      key: "time",
      label: "Thời gian",
      children: <>{bookingTaxi?.pickupTime}</>,
    },
    {
      key: "voucher",
      label: "Giảm giá",
      children: (
        <div className="text-[16px] text-[#01AD53]">
          {bookingTaxi?.amountDiscount?.toLocaleString("vi-VN")} đ
        </div>
      ),
    },
    {
      key: "totalPrice",
      label: "Cước phí",
      children: (
        <div className="text-[16px] text-red-700 font-semibold">
          {bookingTaxi?.amount?.toLocaleString("vi-VN")} đ
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col items-center py-[48px]">
      <div className="w-[90%]">
        <div className="w-full">
          {/* <img src={BgSuccess} alt="" className="w-full" /> */}
          <div className="flex flex-col justify-center gap-4">
            {!loading && (
              <>
                {statusPayment ? (
                  <div className="bg-white p-4 rounded-lg flex flex-col">
                    <div className="text-[24px] text-[#00AB56] font-bold text-center mb-4 uppercase">
                      Thanh toán thành công
                    </div>
                    {!_.isNil(bookingTaxi) && (
                      <Descriptions
                        title="Thông tin chuyến đi"
                        bordered
                        items={items}
                      />
                    )}
                  </div>
                ) : (
                  <div className="flex flex-col bg-white rounded-lg p-4">
                    <div className="text-[24px] text-[#D72F20] font-bold text-center uppercase">
                      Thanh toán thất bại
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="text-center text-[14px] text-white">
              Tham khảo chuyến xe khác ngay bây giờ!
            </div>
          </div>
        </div>
        {/* <div className="w-full mt-4 px-4">
          <div className="flex justify-between">
            <Button
              onClick={() => {
                window.location.href = "/booking-taxi";
              }}
              className="w-[45%] h-[45px] rounded-[10px] text-center mx-auto bg-white text-[#01AD53] border-[#01AD53] text-[15px]"
            >
              <span className="font-semibold">Trang chủ</span>
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PaymentResult;
